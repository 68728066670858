import { SET_VIEW, UNLOCK_FIRST_CHALLENGE, TOGGLE_TITLE_VISIBILITY }  from './actions';

// Initial state
const initialState = {
  currentView: 'landing',  // other possible values: 'about', 'challenges'
  challenges: [
    { actualTitle: 'The Emotional Enigma', unlocked: true },
    { actualTitle: 'Harmonics of Humanity', unlocked: false },
    { actualTitle: 'Ethics of Existence', unlocked: false },
    { actualTitle: 'Coalescence Conundrum', unlocked: false },
  ],
  titleVisible: false,  // New property for title visibility
  currentChallenge: null,
};


const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW:
      return {
        ...state,
        currentView: action.payload.view,
        currentChallenge: action.payload.challengeNumber,
      };
    case UNLOCK_FIRST_CHALLENGE:
      return {
        ...state,
        challenges: state.challenges.map((challenge, index) => 
          index === 0 ? { ...challenge, unlocked: true } : challenge
        ),
      };
    case TOGGLE_TITLE_VISIBILITY:
       return {
        ...state,
        titleVisible: action.payload,
      };
    default:
      return state;
    }
};

export default viewReducer;