import React from 'react';
import '../../SASS/Challenge4.scss'

const Challenge4 = () => {
  return (
    <div className="challenge-page-4">
      <h2>Challenge 4: Coalescence Conundrum</h2>
      {/* Content for Challenge 1 goes here */}
    </div>
  );
};

export default Challenge4;