import { configureStore } from '@reduxjs/toolkit';
import viewReducer from './reducers';
import audioReducer from './audioSlice';  // Import the new reducer

const store = configureStore({
  reducer: {
    view: viewReducer,
    audio: audioReducer,  // Add the new reducer
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;