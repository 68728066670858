import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import heartbeat1 from '../AUDIO/heartbeat1.wav';
import bgAudio1 from '../AUDIO/bgAudio1.wav';
import navHoverAudio from '../AUDIO/NavHover.wav';
import { toggleAudioMuted } from '../util/audioSlice';  // Import the action

const AudioControl = () => {
  const dispatch = useDispatch();
  const audioMuted = useSelector(state => state.audio.audioMuted);  // Get audioMuted from Redux state
  const [audioElement, setAudioElements] = useState(null);

  useEffect(() => {
    // Initialize the heartbeat sound
    const heartbeatAudio = new Audio(heartbeat1);
    heartbeatAudio.loop = true;
    heartbeatAudio.muted = audioMuted; // Set initial mute state
    heartbeatAudio.autoplay = true; // Attempt to autoplay (may not work on all browsers)
    heartbeatAudio.volume = 0.8; // Set initial volume to 80%
  
    // Initialize the background music
    const backgroundMusicAudio = new Audio(bgAudio1); 
    backgroundMusicAudio.loop = true;
    backgroundMusicAudio.muted = audioMuted; // Set initial mute state
    backgroundMusicAudio.autoplay = true; 

    // Initialize the nav hover sound
    const navHoverAudioElement = new Audio(navHoverAudio);
    navHoverAudioElement.muted = audioMuted;  // Set initial mute state
  
    // Set both audio elements in the state
    setAudioElements({ heartbeatAudio, backgroundMusicAudio, navHoverAudioElement });
  
    return () => {
      heartbeatAudio.pause();
      heartbeatAudio.src = '';
      backgroundMusicAudio.pause();
      backgroundMusicAudio.src = '';
      navHoverAudioElement.pause();
      navHoverAudioElement.src = '';
      setAudioElements(null);
    };
  }, [audioMuted]);

  const toggleSound = () => {
    dispatch(toggleAudioMuted()); 
    if (audioElement) {
      Object.values(audioElement).forEach(audio => audio.muted = !audio.muted);
    }
  };

  return (
    <button className={`button-mute ${audioMuted ? 'unmuted' : 'muted'}`} onClick={toggleSound}>
      {audioMuted ? 'Press for Sound' : 'Mute Sound'}
    </button>
  );
};

export default AudioControl;