import React from 'react';
import { useSelector } from 'react-redux';
import NavigationObj from './components/NavigationObj';
import About from './components/About';
import Challenges from './components/Challenges';
import Landing from './components/Landing';
import Information from './components/Information';
import Layout from './components/Layout';
import Background from './components/Background';
import AudioControl from './components/AudioControl';
import { AnimatePresence, motion } from 'framer-motion';

import Challenge1 from './components/ChallengeSet1/Challenge1';
import Challenge2 from './components/ChallengeSet1/Challenge2';
import Challenge3 from './components/ChallengeSet1/Challenge3';
import Challenge4 from './components/ChallengeSet1/Challenge4';

const App = () => {
  const currentView = useSelector(state => state.view.currentView);

  const getComponent = (view) => {
    switch(view) {
      case 'about':
        return <About />;
      case 'challenges':
        return <Challenges />;
      case 'information':
        return <Information />;
      case 'challenge1':
        return <Challenge1 />;
      case 'challenge2':
        return <Challenge2 />;
      case 'challenge3':
        return <Challenge3 />;
      case 'challenge4':
        return <Challenge4 />;
      case 'landing':
      default:
        return <Landing />;
    }
  };

  return (
    <div className="app-container">
      <Background />
      <AudioControl />
      <Layout>
        <AnimatePresence mode='wait'>
          <motion.div
            key={currentView}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {getComponent(currentView)}
          </motion.div>
        </AnimatePresence>
        <NavigationObj label="About" view="about" className="nav-about" />
        <NavigationObj label="[Unkown_Data: Decoding In Progress...]" view="challenges" className="nav-challenges" />
        <NavigationObj label="Home" view="landing" className="nav-home" />
        <NavigationObj label="ERROR" view="information" className="nav-information" />
      </Layout>
    </div>
  );
};

export default App;