import React, { useState, useEffect } from 'react';  // Added useEffect
import '../../../SASS/C1Puzzle1.scss';
import axios from 'axios';
import { eventEmitter } from '../../../util/eventEmitter';

const C1Puzzle1 = () => {
  // State to keep track of the input values
  const [inputFields, setInputFields] = useState(Array(6).fill(''));
  // New state to hold the correctness of each input field
  const [inputCorrectness, setInputCorrectness] = useState(Array(6).fill(null));

  // Function to handle video load
  const handleVideoLoad = (e) => {
    handleResize(e.target);
  };

  // Function to handle window resize and set input positions
  const handleResize = () => {
    const videoElement = document.querySelector('.video-background');

    // Obtain video dimensions
    const videoWidth = videoElement.clientWidth;
    const videoHeight = videoElement.clientHeight;

    // Calculate dimensions for input fields based on video dimensions
    const inputWidth = videoWidth * 0.08; // For example, 8% of video width
    const inputHeight = videoHeight * 0.14; // For example, 8% of video height

    // Calculate the starting position for the first input field
    const startX = videoWidth * -0.312; // Starting X position (30% of video width)
    const startY = videoHeight * -0.07; // Starting Y position (40% of video height)
    
    // Calculate the gap between each input field
    const gap = videoWidth * 0.0275; // For example, 8% of video width

    // Loop through each input field to set its dimensions and position
    for (let i = 0; i < 6; i++) {
      const inputElement = document.querySelector(`.input-${i}`);
      
      // Set calculated dimensions
      inputElement.style.width = `${inputWidth}px`;
      inputElement.style.height = `${inputHeight}px`;

      // Calculate and set positions
      const top = startY;
      const left = startX + (i * (inputWidth + gap)); // Increment by width + gap for each next element
      inputElement.style.top = `${top}px`;
      inputElement.style.left = `${left}px`;
    }

    // Obtain a reference to the submit button
    const submitButton = document.querySelector('.submit-button');

    // Calculate dimensions for the submit button based on video dimensions
    const buttonWidthRatio = 0.08; // Width of the button relative to the video width
    const buttonHeightRatio = 0.15; // Height of the button relative to the video height
    const buttonTopOffset = 0.28; // Vertical offset from the top of the video, in ratio
    const buttonLeftOffset = -0.04; // Horizontal offset from the left of the video, in ratio

    
    // Calculate the position for the submit button
    const buttonTop = videoHeight * buttonTopOffset; 
    const buttonLeft = videoWidth * buttonLeftOffset;
    
    // Calculate dimensions for the submit button based on video dimensions
    const buttonWidth = videoWidth * buttonWidthRatio;
    const buttonHeight = videoHeight * buttonHeightRatio;

    // Set calculated dimensions and position
    submitButton.style.width = `${buttonWidth}px`;
    submitButton.style.height = `${buttonHeight}px`;
    submitButton.style.top = `${buttonTop}px`;
    submitButton.style.left = `${buttonLeft}px`;
  };

  // Call the function initially and also attach it to the window resize event
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to handle the change in input fields
  const handleInputChange = (e, index) => {
    const newInputFields = [...inputFields];
    newInputFields[index] = e.target.value;
    setInputFields(newInputFields);
  };

  // Function to handle the submission of the form
  const handleSubmit = async () => {
    try {
      // Fetch the puzzle details from the backend using the puzzle's unique ID
      const puzzleId = process.env.REACT_APP_PUZZLE_ID_1; // For testing purposes
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/puzzles/${puzzleId}`);
      const puzzle = res.data;

      // Split the answer into segments (2 characters each, except the last one if it's single)
      const answerSegments = puzzle.answer.match(/.{1,2}/g);

      // Compare each segment with the corresponding input field
      let isCorrect = true;
      for (let i = 0; i < answerSegments.length; i++) {
        if (answerSegments[i] !== inputFields[i]) {
          isCorrect = false;
          break;
        }
      }

      // update input fields correctness
      const newInputCorrectness = [];
      for (let i = 0; i < answerSegments.length; i++) {
      newInputCorrectness[i] = answerSegments[i] === inputFields[i];
      }
      setInputCorrectness(newInputCorrectness);

      if (isCorrect) {
        // Update puzzle status to 'Completed'
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/puzzles/${puzzleId}`, {
          status: 'Completed'
        });
  
        // Update material status to 'Active'
        const materialId = process.env.REACT_APP_DEFAULT_FINALE_MATERIAL_ID; // Replace with actual Material ID
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/materials/${materialId}`, {
          status: 'Active'
        });
  
        // Placeholder: Logic to activate the next puzzle using the nextPuzzleId field
        // ...

        eventEmitter.emit('puzzleCompleted', true);
        
      } else {
        // Logic to highlight incorrect fields
        // This is already done via the setInputCorrectness() state update
      }
    } catch (error) {
      console.error("Error updating puzzle or material:", error);
    }
  };

  // Added this useEffect
  useEffect(() => {
    const videoElement = document.querySelector('.video-background');
    videoElement.addEventListener('loadedmetadata', handleVideoLoad);
    
    window.addEventListener('resize', () => handleResize(null));
    
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleVideoLoad);
      window.removeEventListener('resize', () => handleResize(null));
    };
  });

  return (
    <div className="puzzle1-container">
      <video className="video-background" autoPlay loop muted>
        <source src="/IMG/Puzzle1Submission.mp4" type="video/mp4" />
      </video>
      <div className="input-container">
      {inputFields.map((value, index) => (
        <input 
          type="text" 
          maxLength="2" 
          value={value} 
          onChange={e => handleInputChange(e, index)} 
          key={index}
          className={`input-field input-${index} ${inputCorrectness[index] === true ? 'correct' : inputCorrectness[index] === false ? 'incorrect' : ''}`}
        />
      ))}
        <button onClick={handleSubmit} className="submit-button">Submit</button>
      </div>
    </div>
  );
};

export default C1Puzzle1;