import React, { useEffect, useState } from 'react';
import '../../SASS/Challenge1.scss';
import axios from 'axios';
import { eventEmitter } from '../../util/eventEmitter';
import MaterialList from '../MaterialList';
import C1PuzzleArchive from '../ChallengeSet1/Challenge1Puzzles/C1PuzzleArchive'; // Import the Archive component
import C1Puzzle1 from './Challenge1Puzzles/C1Puzzle1';

const Challenge1 = () => {
  const [activePuzzle, setActivePuzzle] = useState(null);
  const [selectedArchivedPuzzle, setSelectedArchivedPuzzle] = useState(null);
  const [finaleMaterial, setFinaleMaterial] = useState(null);

  // Fetch the active puzzle and its finale material
  const defaultFinaleMaterialId = process.env.REACT_APP_DEFAULT_FINALE_MATERIAL_ID;

  useEffect(() => {
    // Initial fetch for puzzles
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/puzzles`)
      .then(response => {
        const activePuzzle = response.data.find(puzzle => puzzle.status === 'Active');
        if (activePuzzle) {
          setActivePuzzle(activePuzzle);
          setFinaleMaterial(activePuzzle.finaleMaterialId);
        } else {
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/materials/${defaultFinaleMaterialId}`)
            .then(res => {
              setFinaleMaterial(res.data);
            });
        }
      })
      .catch(error => console.error('Error fetching puzzles:', error));
  
    // Event listener for puzzle completion
    const handlePuzzleComplete = () => {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/puzzles`)
        .then(response => {
          const newActivePuzzle = response.data.find(puzzle => puzzle.status === 'Active');
          if (newActivePuzzle) {
            setActivePuzzle(newActivePuzzle);
            setFinaleMaterial(newActivePuzzle.finaleMaterialId);
          } else {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/materials/${defaultFinaleMaterialId}`)
              .then(res => {
                setFinaleMaterial(res.data);
              });
          }
        })
        .catch(error => console.error('Error fetching puzzles after completion:', error));
    };
  
    eventEmitter.on('puzzleCompleted', handlePuzzleComplete);
  
    // Clean up
    return () => {
      eventEmitter.off('puzzleCompleted', handlePuzzleComplete);
    };
  }, []);

  const handleArchivedPuzzleSelect = (puzzle) => {
    setSelectedArchivedPuzzle(puzzle);
  };


  return (
    <>
      <div className="challenge-page-1">
        <h2>The Emotional Enigma</h2>
        {/* Separate div for C1PuzzleArchive */}
        <div>
          <C1PuzzleArchive onSelectArchivedPuzzle={handleArchivedPuzzleSelect} />
        </div>
      </div>

      {/* Separate div for MaterialList */}
    <div>
       <MaterialList puzzle={selectedArchivedPuzzle || activePuzzle} />
    </div>

      <div className="puzzle-container">
        {activePuzzle ? (
          <C1Puzzle1 />
        ) : finaleMaterial ? (
          // Display finale material when there's no active puzzle
          <div className="center-iframe">
            <iframe
              src={finaleMaterial?.url}
              title="Unlocked Video"
              width="560"
              height="315"
            />
          </div>
        ) : (
          <div>No puzzles available</div>
        )}
      </div>
    </>
  );
};

export default Challenge1;