import React from 'react';
import '../SASS/MaterialDisplay.scss';

const MaterialDisplay = ({ material, onClose }) => {
  // Check if material is null or undefined
  if (!material) {
    console.log('Material is null or undefined');
    return null;
  }

  if (material.type === 'Video') {
    return (
      <div className="material-video-container">
        <iframe
          src={material.url}
          title="Material Video"
          allowFullScreen
        />
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    );
  } else if (material.type === 'Document') {
    return (
      <div className="material-iframe-container">
        <iframe
          src={material.url}
          title="Material Document"
          className="material-iframe"
          allowFullScreen
        ></iframe>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    );
  } else {
    // If material type is neither 'video' nor 'document', return null
    return null;
  }
};

export default MaterialDisplay;