import React from 'react';
import '../SASS/Background.scss';

const Background = () => {
  return (
    <div className="space-background">
      <video className="background-video" autoPlay loop muted onCanPlay={(e) => e.currentTarget.playbackRate = 0.6}>
        <source src="/IMG/GalaxyBG.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default Background;