import React from 'react';
import heartbeat1 from '../AUDIO/heartbeat1.wav';
import '../SASS/Logo.scss';

const LogoAnimation = () => {
  return (
    <div className="logo-container">
      <audio preload="auto">
        <source src={heartbeat1} type="audio/wav" />
      </audio>
      <img src="/IMG/Logo.png" alt="LOGO" className="logo-image" />
    </div>
  );
};

export default LogoAnimation;