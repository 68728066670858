import { createSlice } from '@reduxjs/toolkit';

export const audioSlice = createSlice({
  name: 'audio',
  initialState: {
    audioMuted: true,
  },
  reducers: {
    toggleAudioMuted: (state) => {
      state.audioMuted = !state.audioMuted;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleAudioMuted } = audioSlice.actions;

export default audioSlice.reducer;