import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setView } from '../util/actions';

const ChallengesList = () => {
  // Accessing the Redux store state and dispatch function
  const challenges = useSelector(state => state.view.challenges);
  const dispatch = useDispatch();  // Get the dispatch function

  // Handler function for challenge selection
  const handleChallengeSelect = (challengeIndex) => {
    dispatch(setView(`challenge${challengeIndex + 1}`, challengeIndex + 1));
  };

  return (
    <ul className="challenges-list">
      {challenges.map((challenge, index) => (
        <li key={index} className={challenge.unlocked ? 'unlocked' : 'locked'}>
          <button 
            onClick={challenge.unlocked ? () => handleChallengeSelect(index) : null} 
            disabled={!challenge.unlocked}
          >
            {challenge.unlocked ? challenge.actualTitle : 'REDACTED'}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ChallengesList;