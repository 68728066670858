import React from 'react';
import Countdown from 'react-countdown';
import '../SASS/Countdown.scss';

const CountdownTimer = ({ targetDate, onComplete }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Defer the execution of onComplete
        setTimeout(() => {
            onComplete();
        }, 0);
        return null;  // Render nothing when completed
    } else {
        return <span className="countdown-timer">{days}:{hours}:{minutes}:{seconds}</span>;
    }
};

  return (
    <div className="countdown-container">
      <Countdown date={targetDate} renderer={renderer} />
    </div>
  );
};
  
export default CountdownTimer;