import '../SASS/About.scss';

const About = () => {
  return (
      <div className="about-content">
        {/* Unique content for the About section */}
        <h1>About Us</h1>

          <div className='about-content-box'>
            <p>From The Founder:</p>
            <p>"At the heart of every enigma lies a spark of truth awaiting discovery. 
              In the labyrinth of existence, each turn holds a clue, each path a lesson 
              leading us towards greater understanding. We endeavor to traverse the unknown, 
              paving a road of enlightenment for all of humanity. We believe in the boundless 
              potential housed within the heart of collective endeavor. With each challenge we unravel, 
              we step closer to a horizon of untold possibilities. We, at the Insight Collective, are driven 
              by our motto:"</p>

              <p>"Understanding Humanity: A Collective Venture For Our Future"</p>
          </div>

      </div>
  );
};

export default About;