import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setView, toggleTitleVisibility} from '../util/actions';  // combined import
import ChallengesList from './ChallengesList';
import CountdownTimer from './Countdown';
import axios from 'axios';
import '../SASS/Challenges.scss';

const Challenges = () => {
  const dispatch = useDispatch();
  const [challengeUnlocked, setChallengeUnlocked] = useState(false);
  const [targetDate, setTargetDate] = useState(null);
  const [timerCompleted, setTimerCompleted] = useState(false);  // New state
  const titleVisible = useSelector(state => state.view.titleVisible);  

  useEffect(() => {
    // Fetch the countdown data from the server
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/countdown`)
    .then(response => {
        setTargetDate(new Date(response.data.unlockTime));
        setChallengeUnlocked(response.data.challengesUnlocked);
        if (response.data.challengesUnlocked) {
          dispatch(toggleTitleVisibility(true));  // Update the Redux state
        }
      })
      .catch(error => {
        console.error('Error fetching countdown data: ', error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (timerCompleted) {
      dispatch(toggleTitleVisibility(true));  // Dispatch the action to show the title

      // Optionally, update the server that challenges are now unlocked
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/countdown`, {
        challengesUnlocked: true,
        unlockTime: targetDate.toISOString()
      })
      .catch(error => console.error('Error updating countdown state: ', error));
    }
  }, [timerCompleted, dispatch, targetDate]);

  const handleChallengeSelect = (challengeIndex) => {
    const challengeView = `challenge${challengeIndex + 1}`;
    dispatch(setView(challengeView, challengeIndex));
  };


  const handleCompletion = () => {
    setChallengeUnlocked(true);
    setTimerCompleted(true);  // Set a flag to indicate the timer has completed
  };

  return (
    <div className="challenges-content">
      {titleVisible && <h1 className='challenge-set-title'>[Experiment - OAIB]</h1>}
      {(!challengeUnlocked && targetDate) && (
        <CountdownTimer 
          targetDate={targetDate} 
          onComplete={handleCompletion} 
        />
      )}
      {challengeUnlocked && <ChallengesList onChallengeSelect={handleChallengeSelect} />}
    </div>
  );
};

export default Challenges;