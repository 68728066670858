import React from 'react';
import '../../SASS/Challenge2.scss'

const Challenge2 = () => {
  return (
    <div className="challenge-page-2">
      <h2>Challenge 2: Harmonics of Humanity</h2>
      {/* Content for Challenge 1 goes here */}
    </div>
  );
};

export default Challenge2;