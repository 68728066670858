import React from 'react';
import LogoAnimation from './LogoAnimation';  
import '../SASS/Landing.scss';

const Landing = () => {
  return (
    <div>
        <LogoAnimation />
        <div className="landing-content">
          <h1>Welcome to the Insight Collective</h1>
          <p>"Understanding Humanity: A Collective Venture For Our Future"</p>
        </div>
    </div>
  );
};

export default Landing;