import React from 'react';
import '../../SASS/Challenge3.scss'

const Challenge3 = () => {
  return (
    <div className="challenge-page-3">
      <h2>Challenge 3: Ethics of Existence</h2>
      {/* Content for Challenge 1 goes here */}
    </div>
  );
};

export default Challenge3;