import React from 'react';
import '../SASS/Layout.scss';

const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      {children}  {/* Unique content will be injected here */}
    </div>
  );
};

export default Layout;