import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import '../SASS/MaterialList.scss';
import MaterialDisplay from './MaterialDisplay';

const MaterialList = ({ puzzle }) => {
  const [loadedMaterials, setLoadedMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeMaterials, setActiveMaterials] = useState([]);

  const INITIAL_LOAD = 6;
  const LOAD_MORE = 6;

  const fetchMaterialsDetails = async (materialIds) => {
    try {
      const materials = await Promise.all(
        materialIds.map(id => axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/materials/${id}`))
      );
      return materials.map(response => response.data);
    } catch (error) {
      console.error("Error fetching material details:", error);
      return [];
    }
  };

  const loadInitialMaterials = useCallback(async () => {
    if (puzzle && puzzle.additionalMaterials) {
      try {
        const materials = await fetchMaterialsDetails(puzzle.additionalMaterials);
        const activeMaterials = materials.filter(m => m.status === 'Active');
        setLoadedMaterials(activeMaterials.slice(0, INITIAL_LOAD));
        setHasMore(activeMaterials.length > INITIAL_LOAD);
        setActiveMaterials(activeMaterials);
      } catch (error) {
        console.error("Error loading initial materials:", error);
        setLoadedMaterials([]);
        setHasMore(false);
      }
    } else {
      setLoadedMaterials([]);
      setHasMore(false);
    }
  }, [puzzle]);

  const loadMoreMaterials = useCallback(async () => {
    if (puzzle && !loading) {
      setLoading(true);

      try {
        const remainingMaterials = activeMaterials
          .slice(loadedMaterials.length, loadedMaterials.length + LOAD_MORE);

        if (remainingMaterials.length > 0) {
          const materialIds = remainingMaterials.map(material => material._id);

          if (materialIds.length > 0) {
            const additionalMaterialsDetails = await fetchMaterialsDetails(materialIds);

            setLoadedMaterials(prevMaterials => [...prevMaterials, ...additionalMaterialsDetails]);

            if (loadedMaterials.length + LOAD_MORE >= activeMaterials.length) {
              setHasMore(false); // No more materials to load
            }
          } else {
            console.log("No valid material IDs to fetch");
            setHasMore(false); // No more materials to load
          }
        } else {
          setHasMore(false); // No more materials to load
        }
      } catch (error) {
        console.error("Error loading more materials:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [puzzle, loadedMaterials, loading, activeMaterials]);
   
  
  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  const handleClose = () => {
    setSelectedMaterial(null);
  };

  useEffect(() => {
    // Scroll event handling
    const handleScroll = () => {
      const scrollContainer = document.querySelector(".material-list-scroll-container");

      if (scrollContainer) {
        const { scrollTop, clientHeight, scrollHeight } = scrollContainer;

        if (scrollTop + clientHeight >= scrollHeight - 20 && hasMore) {
          loadMoreMaterials();
        }
      }
    };

    const scrollContainer = document.querySelector(".material-list-scroll-container");
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreMaterials, hasMore]);

  useEffect(() => {
    // Load initial materials
    loadInitialMaterials();
  }, [loadInitialMaterials]);

  return (
    <div className="material-list-container">
      <h3>Available Materials</h3>
      <div className="material-list-scroll-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {loadedMaterials.length > 0 ? (
          <ul className="material-list">
            {loadedMaterials.map((material, index) => (
              <li key={index} onClick={() => handleMaterialClick(material)}>
                {material.title}
              </li>
            ))}
          </ul>
        ) : (
          <div>No materials available</div>
        )}
        {loading && <div>Loading more materials...</div>}
      </div>

      {/* Display MaterialDisplay component when selectedMaterial is not null */}
      {selectedMaterial && (
        <div className="material-display-container">
          <MaterialDisplay material={selectedMaterial} onClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default MaterialList;