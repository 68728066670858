import '../SASS/Information.scss';

const Information = () => {
    return (
      <div className="information-content">
        <h1>Application Information</h1>
        <div className='information-content-box'>
          <section>
            <h2>Introduction</h2>
            <p>Welcome to this unique project. At first this started as an ARG, but slowly it turned into more of an interactive narrative game. My aim is to provide an immersive experience, compelling narrative, interactive puzzles, and promote community engagement.
             The experience is structured into challenge sets, akin to "seasons".  
            </p> 

            <p>Each challenge set comprises a series of puzzles. This website serves as a common ground where participants can view challenges and work on puzzles. Supplementary
             material will also be periodically released here. Challenge sets will follow an overarching theme and cover a range of significant topics.
            </p>

            <p><strong>Please Note:</strong> Some topics are <strong>not suitable for younger audiences</strong> and this should be considered a <strong>mature experience</strong>. 
            The aim is to address these sensitive topics to promote critical thinking and foster discussions on real-world issues.</p>

            <p>The experience is designed to be a collective effort, with puzzles that are increasingly challenging and require collaboration. I <strong>highly</strong> encourage community cooperation and discussions. 
              Players' actions and feedback will influence the narrative and future challenges, providing an evolving experience for everyone.</p>

            <p>Thank you for exploring my project. I hope it provides an engaging experience, and I look forward to your participation in the community.</p>
          </section>
        <hr />
        <section>
          <h2>Features</h2>
          <ul>
            <li><strong>An interactive web application as the hub.</strong></li>
            <li><strong>A detailed and engaging narrative.</strong></li>
            <li><strong>Collaborative challenges that encourage teamwork.</strong></li>
            <li><strong>A robust and dynamic system that adapts the narrative based on player choices.</strong></li>
          </ul>
        </section>
        <hr />
        <section>
          <h2>The Technical Side</h2>
          <p>As a soon-to-be software engineering graduate, this project is a pivotal part of my portfolio and represents the final project in my program. My focus is on web development using React and Redux for the frontend, SASS for styling, Node.js and Express for the backend, and MongoDB for data management. I welcome technical discussions and feedback on my code and am always seeking to refine my skills.</p>
          <p>As I continue to learn, please anticipate some initial site issues. My priority is to resolve any technical problems swiftly. The release of the first puzzle marks a testing phase to ensure full functionality. Should you encounter issues, I encourage you to contact me for prompt resolutions.</p>
        </section>
        <hr />
        <section>
          <h2>Future Roadmap</h2>
          <p>The roadmap for the first challenge set includes 4 challenges, each with 5 puzzles. As I am responsible for both application development and content creation, progress requires considerable time. This initial release serves as a "beta," with the first puzzle acting as a prologue to the theme for this challenge set.</p>
        </section>
        <hr />
        <section>
          <h2>Contact Information</h2>
          <p>For feedback on the project, whether technical or narrative, please don't hesitate to reach out at: <strong>vpasb(2^12 - 4)@znvyl.pbz</strong> (Apply a ROT-13 Cypher to letters, and solve the equation to get decrypted email)</p>
        </section>
        </div>
      </div>
    );
  };
  
  export default Information;
  