import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setView } from '../util/actions';
import { motion } from 'framer-motion';
import navHoverAudio from '../AUDIO/NavHover.wav';
import '../SASS/NavigationObj.scss';  // Import your styles

const NavigationObj = ({ label, view, className }) => {
    const dispatch = useDispatch();
    const currentView = useSelector(state => state.view.currentView);
    const navRef = useRef(null);
    const audioMuted = useSelector(state => state.audio.audioMuted);

    const handleClick = () => {
        // Prevent the click action for challenges and information view
        // if (view !== 'challenges' && view !== 'information') {
        //     dispatch(setView(view));
        // }

        // Prevent the click action for information view
        if (view !== 'information') {
            dispatch(setView(view));
        }

        //dispatch(setView(view));
    };

    useEffect(() => {
        const audio = new Audio(navHoverAudio);
        const handleHover = () => {
            if (!audioMuted) {
              audio.currentTime = 0;
              audio.play();
              audio.playbackRate = 1.5;
            }
          };
        
        const element = navRef.current;
        element.addEventListener('mouseenter', handleHover);
        
        return () => {
            element.removeEventListener('mouseenter', handleHover);
        };
    }, [audioMuted, className]);

    return (
        <motion.div
            ref={navRef}
            className={`navigation-obj ${className} ${currentView === view ? 'active' : ''}`}
            onClick={handleClick}
            whileHover={{ scale: 1.2 }}
        >
            <span>{label}</span>
        </motion.div>
    );
};
export default NavigationObj;