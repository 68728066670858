// Action type
export const SET_VIEW = 'SET_VIEW';

// Action creator
export const setView = (view, challengeNumber) => ({
  type: SET_VIEW,
  payload: { view, challengeNumber },
});

// New action type for unlocking the first challenge
export const UNLOCK_FIRST_CHALLENGE = 'UNLOCK_FIRST_CHALLENGE';

// Action creator for unlocking the first challenge
export const unlockFirstChallenge = () => ({
  type: UNLOCK_FIRST_CHALLENGE,
});

// New action type for toggling the visibility of the title
export const TOGGLE_TITLE_VISIBILITY = 'TOGGLE_TITLE_VISIBILITY';

// Action creator for toggling the visibility of the title
export const toggleTitleVisibility = (isVisible) => ({
  type: TOGGLE_TITLE_VISIBILITY,
  payload: isVisible,
});