import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../SASS/C1PuzzleArchive.scss';

const C1PuzzleArchive = ({ onSelectArchivedPuzzle }) => {
  const [completedPuzzles, setCompletedPuzzles] = useState([]);

  useEffect(() => {
    // Fetch puzzles and filter for completed ones
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/puzzles`)
      .then(response => {
        const completed = response.data.filter(puzzle => puzzle.status === 'Completed');
        setCompletedPuzzles(completed);
      })
      .catch(error => console.error('Error fetching puzzles:', error));
  }, []);

  return (
    <div className="puzzle-archive-container">
      <h3>Archive</h3>
      <ul className="puzzle-archive-list">
        {completedPuzzles.map((puzzle, index) => (
          <li key={index} onClick={() => onSelectArchivedPuzzle(puzzle)}>
            {puzzle.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default C1PuzzleArchive;